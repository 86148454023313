import { useEffect, useState } from 'react';
import Axios from 'axios';
import turf from 'turf';
import { useSelector } from 'react-redux';
import { environment } from 'env-config';

import { FeaturePropertyTypeEnum, IFeatureDTO } from '@halter-corp/topography-service-client';

import { paddocks } from 'store/selectors/topography.selectors';

import HttpApiService from './http-api.service';

type Bounds = {
  northWest: L.LatLngTuple;
  southEast: L.LatLngTuple;
  minZoom: number;
  maxZoom: number;
};

type Zoom = {
  minZoom: number;
  maxZoom: number;
};

type FarmMapMetadata = {
  bounds: L.LatLngTuple[];
  zoom: Zoom;
  tileUrl: string;
};

const TopographyService = {
  useFarmMapMetadata: (currentFarmId?: string): FarmMapMetadata | null => {
    const [metadata, setMetadata] = useState<FarmMapMetadata | null>(null);

    useEffect(() => {
      (async () => {
        if (currentFarmId) {
          const farmMapUrl = `https://halter-admin-dashboard-tiles.${environment}.halter.io/${currentFarmId}`;

          const bounds = await Axios.get<Bounds>(`${farmMapUrl}/bounding.json`);

          if (bounds.data.northWest) {
            setMetadata({
              bounds: [bounds.data.northWest, bounds.data.southEast],
              zoom: {
                minZoom: bounds.data.minZoom || 13,
                maxZoom: bounds.data.maxZoom || 23,
              },
              tileUrl: `${farmMapUrl}/{z}/{x}/{y}.png`,
            });
          } else {
            const featureApi = await HttpApiService.getFeatureApi();

            const viewcenter = await featureApi.findAll(undefined, FeaturePropertyTypeEnum.Viewcenter);
            if (viewcenter.data && viewcenter.data.length > 0) {
              const buffer = turf.buffer(viewcenter.data[0].feature, 1);
              const viewCenterBounds = buffer.geometry.coordinates[0].map(
                (c) => [c[1], c[0]] as L.LatLngTuple
              );

              setMetadata({
                bounds: viewCenterBounds,
                zoom: {
                  minZoom: 13,
                  maxZoom: 23,
                },
                tileUrl: 'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
              });
            } else {
              setMetadata(null);
            }
          }
        }
      })();
    }, [currentFarmId]);

    return metadata;
  },

  fetchDatum: async (farmId: string): Promise<IFeatureDTO | null> => {
    const api = await HttpApiService.getFeatureApi(farmId);
    const { data } = await api.findAll(undefined, FeaturePropertyTypeEnum.Datum);
    return data.length > 0 ? data[0] : null;
  },

  fetchViewcenter: async (farmId: string): Promise<IFeatureDTO | null> => {
    const api = await HttpApiService.getFeatureApi(farmId);
    const { data } = await api.findAll(undefined, FeaturePropertyTypeEnum.Viewcenter);
    return data.length > 0 ? data[0] : null;
  },

  fetchAllPaddocksForFarm: async (farmId: string): Promise<IFeatureDTO[]> => {
    const api = await HttpApiService.getFeatureApi(farmId);
    const { data } = await api.findAll(undefined, FeaturePropertyTypeEnum.Paddock);
    return data;
  },

  usePaddocks: () => useSelector(paddocks),
};

export default TopographyService;
