import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useDispatch } from 'store';
import Button from 'application/components/button';
import towerEffects from 'store/effects/tower.effects';
import { selectTowers } from 'store/selectors/tower.selectors';
import { selectCurrentFarm } from 'store/selectors/farm-infra.selectors';
import { DeviceActionDialogProps } from '../DevicesActionsWrapper';

const TowerSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  & > *:not(:first-child) {
    margin-top: -6px;
  }
`;

const AssignDevicesDialog: FunctionComponent<DeviceActionDialogProps> = (props) => {
  const { open, onClose, devices } = props;
  const [selectedTowerId, setSelectedTowerId] = useState<string | null>(null);

  const dispatch = useDispatch();
  const currentFarm = useSelector(selectCurrentFarm);
  const currentFarmTowers = useSelector(selectTowers);

  const handleAssignDevices = () => {
    if (!selectedTowerId) return;
    devices.forEach((device) =>
      dispatch(
        towerEffects.updateDeviceContextByIdAndFarmId({
          id: device.id,
          farmId: currentFarm,
          updateDeviceContextDto: { towerId: selectedTowerId, name: selectedTowerId },
        })
      )
    );
    onClose();
  };

  useEffect(() => {
    setSelectedTowerId(null);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Re-assign Devices To Tower</DialogTitle>
      <DialogContent>
        <Typography style={{ fontWeight: 500, fontSize: 18 }}>Tower to select:</Typography>
        <TowerSelectWrapper>
          {currentFarmTowers.map((tower) => (
            <FormControlLabel
              key={tower.id}
              label={tower.id}
              control={
                <Radio
                  color="primary"
                  checked={selectedTowerId === tower.id}
                  onChange={() => setSelectedTowerId(tower.id)}
                  value={tower.id}
                />
              }
            />
          ))}
        </TowerSelectWrapper>

        <Box display="flex" justifyContent="space-between" alignItems="center" paddingBottom={1}>
          <Button disabled={!selectedTowerId} text="Re-assign devices" onClick={handleAssignDevices} />
          <Button backgroundColour="#616161" text="Cancel" onClick={onClose} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AssignDevicesDialog;
