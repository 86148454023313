export const dev = {
    awsConfig: {
        "aws_project_region": "ap-southeast-2",
        "aws_cognito_identity_pool_id": "ap-southeast-2:a718da10-2464-48ba-ae97-89054bc6f80a",
        "aws_cognito_region": "ap-southeast-2",
        "aws_user_pools_id": "ap-southeast-2_TgQHONcxR",
        "aws_user_pools_web_client_id": "6gthu8qbaqplnic1ad8cnuv0va",
    },
    firebaseConfig: {
        "firebase_cloud_messaging_sender_id": "518022369714",
    },
    halterConfig: {
        "halter_api_url": "https://api.dev.halter.io",
    },
    mixpanelConfig: {
        "mixpanel_project_token": "110ae0b14471a27f43a50220296503c1",
    },
    simulationConfig: {
        "auth_secret": "GnJTwmcUeNr4gq4xTfs6",
        "halter_api_url": "https://api.staging.halter.io", 
    },
    anonymousAuthConfig: {
        "photo_apc" : {
            "auth_secret": "sYdzOSshyqUTjx6k9mOt",
        }
    }
};
export const staging = {
    awsConfig: {
        "aws_project_region": "ap-southeast-2",
        "aws_cognito_identity_pool_id": "ap-southeast-2:df0322fa-bfc4-466d-9a5b-9dd6e50a4f7f",
        "aws_cognito_region": "ap-southeast-2",
        "aws_user_pools_id": "ap-southeast-2_QEQyfsj09",
        "aws_user_pools_web_client_id": "2nj565tp0pv051jrf40ni0gf1b",
    },
    firebaseConfig: {
        "firebase_cloud_messaging_sender_id": "518022369714",
    },
    halterConfig: {
        "halter_api_url": "https://api.staging.halter.io",
    },
    mixpanelConfig: {
        "mixpanel_project_token": "413a9ace990375929c380ec32ea79974",
    },
    simulationConfig: {
        "auth_secret": "GnJTwmcUeNr4gq4xTfs6",
        "halter_api_url": "https://api.staging.halter.io", 
    },
    anonymousAuthConfig: {
        "photo_apc" : {
            "auth_secret": "bL1cI51CjdDYakVPYbX4",
        }
    }
};
export const prod = {
    awsConfig: {
        "aws_project_region": "ap-southeast-2",
        "aws_cognito_identity_pool_id": "ap-southeast-2:48a880fb-6c12-42cd-867d-172d24a03880",
        "aws_cognito_region": "ap-southeast-2",
        "aws_user_pools_id": "ap-southeast-2_sMhqq7MQr",
        "aws_user_pools_web_client_id": "3ebcsg2p2b4suj988qqhl77a77",
    },
    firebaseConfig: {
        "firebase_cloud_messaging_sender_id": "518022369714",
    },
    halterConfig: {
        "halter_api_url": "https://api.prod.halter.io",
    },
    mixpanelConfig: {
        "mixpanel_project_token": "785f1f96c9cfcf6457f362407003728c",
    },
    simulationConfig: {
        "auth_secret": "GnJTwmcUeNr4gq4xTfs6",
        "halter_api_url": "https://api.staging.halter.io", 
    },
    anonymousAuthConfig: {
        "photo_apc" : {
            "auth_secret": "rjQj4P1eIBtM8MJJzpOr",
        }
    }
};
export const environment = 'staging';
