import React, { FunctionComponent, useEffect, useState, useRef, useMemo } from 'react';
import { Map as LeafletMap, TileLayer, withLeaflet } from 'react-leaflet';
import styled from 'styled-components';
import moment from 'moment';
import { Fab, Icon } from '@material-ui/core';

import TopographyService from 'services/topography.service';
import { sortBy } from 'lodash';
import MeasureControlDefault from 'react-leaflet-measure';
import { IFeatureDTO } from '@halter-corp/topography-service-client';
import {
  selectCowGEventStream,
  selectMappedPositionMetrics,
  selectFilteredDeviceCommandFeatures,
  selectFilteredCattleLocationPaths,
} from 'store/selectors/debug-cattle.selectors';
import { AppState, useSelector } from 'store';
import { getCurrentFarm } from '../../../../../store/selectors/farm-context.selectors';
import DateRangeSlider from '../../components/date-range-slider';
import MapFilterPanel from './map-filter-panel';
import {
  CowgStatusEnum,
  transitionCowgStatuses,
} from '../../../../../store/selectors/map-page-items.selectors/cattle-event.selector';

import EventsSummaryPanel, { CattleEventsSummary } from './events-summary.panel';
import CattleLocationsLayer from './layers/cattle-locations.layer2';
import DeviceCommandsLayer from './layers/device-commands.layer2';
import CattleEventsLayer2 from './layers/cattle-events.layer/cattle-events.layer2';

const measureOptions = {
  position: 'topleft',
  primaryLengthUnit: 'meters',
  secondaryLengthUnit: 'kilometers',
  primaryAreaUnit: 'sqmeters',
  secondaryAreaUnit: 'acres',
  activeColor: '#db4a29',
  completedColor: '#9b2d14',
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const BottomMapWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
  z-index: 999; // keep above the mapping layer
`;

const OtherMapWrapper = styled.div`
  position: absolute;
  top: 1rem;
  left: 4rem;
  z-index: 999; // keep above the mapping layer
`;

export type MapPanelProps = {
  activeDateRange: [Date, Date] | null;
  isFullScreen: boolean;
  onChangeIsFullScreen: (value: boolean) => void;
  searchDateRange: [Date, Date] | null;
};

const MapPanel2: FunctionComponent<MapPanelProps> = ({
  activeDateRange,
  isFullScreen,
  onChangeIsFullScreen,
}) => {
  const [scrubbedDateRange, setScrubbedDateRange] = useState(activeDateRange);
  const MeasureControl = withLeaflet(MeasureControlDefault);

  useEffect(() => setScrubbedDateRange(activeDateRange), [activeDateRange]);

  const mapRef = useRef<LeafletMap>(null);

  useEffect(() => {
    if (mapRef.current != null) mapRef.current.leafletElement.invalidateSize();
  }, [isFullScreen]);

  const currentFarmId = useSelector(getCurrentFarm);
  const metadata = TopographyService.useFarmMapMetadata(currentFarmId);

  const [viewCenter, setViewCenter] = useState<IFeatureDTO | null>(null);
  useEffect(() => {
    setViewCenter(null);
    (async () => {
      const retrievedViewcenter = await TopographyService.fetchViewcenter(currentFarmId);
      setViewCenter(retrievedViewcenter);
    })();
  }, [currentFarmId]);

  const allCattlePaths = useSelector(selectMappedPositionMetrics);
  const allCattleEvents = useSelector(selectCowGEventStream);

  const allAvailableCows = useMemo(
    () => sortBy([...new Set(allCattlePaths.map((cattlePath) => cattlePath.cattleName))]),
    [allCattlePaths]
  );

  const allAvailableCowGStatuses = useMemo(
    () =>
      sortBy([
        ...new Set([...transitionCowgStatuses, ...allCattleEvents.flatMap((event) => event.cowgStatuses)]),
      ]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transitionCowgStatuses, allCattleEvents]
  );

  const [selectedCows, setSelectedCows] = useState<string[] | null>(null);
  const [selectedCowGStatuses, setSelectedCowGStatuses] = useState<CowgStatusEnum[] | null>(null);
  const [cattleEventsLayerData, setCattleEventsLayerData] = useState<CattleEventsSummary[]>([]);

  const commandFeatures = useSelector((state: AppState) =>
    selectFilteredDeviceCommandFeatures(state, {
      maximumDate: scrubbedDateRange?.[1] || null,
      selectedCows,
    })
  );

  const cattlePaths = useSelector((state: AppState) =>
    selectFilteredCattleLocationPaths(state, {
      selectedCows,
      minimumDate: scrubbedDateRange?.[0] || null,
      maximumDate: scrubbedDateRange?.[1] || null,
    })
  );

  if (metadata == null || viewCenter == null) {
    return null;
  }

  return (
    <Wrapper>
      <Fab
        onClick={() => onChangeIsFullScreen(!isFullScreen)}
        style={{
          backgroundColor: '#1769aa',
          color: 'white',
          position: 'absolute',
          top: 16,
          right: 16,
          zIndex: 9999,
        }}
      >
        <Icon>{isFullScreen ? 'chevron_left' : 'chevron_right'}</Icon>
      </Fab>
      <LeafletMap
        ref={mapRef}
        preferCanvas
        center={viewCenter.feature.geometry.coordinates}
        bounds={metadata.bounds}
        maxZoom={metadata.zoom.maxZoom}
        style={{ flex: 1 }}
      >
        <TileLayer url={metadata.tileUrl} />
        {scrubbedDateRange != null && (
          <>
            <DeviceCommandsLayer commandFeatures={commandFeatures} />
            <CattleLocationsLayer cattlePaths={cattlePaths} />
            <CattleEventsLayer2
              onChangeThing={setCattleEventsLayerData}
              selectedCows={selectedCows}
              scrubbedDateRange={scrubbedDateRange}
              selectedCowGStatuses={selectedCowGStatuses || allAvailableCowGStatuses}
            />
            <MeasureControl {...measureOptions} />
          </>
        )}
      </LeafletMap>
      <OtherMapWrapper>
        {allCattleEvents.length > 0 && <EventsSummaryPanel cattleEventsLayerData={cattleEventsLayerData} />}
      </OtherMapWrapper>
      <BottomMapWrapper>
        {scrubbedDateRange != null && activeDateRange != null && (
          <DateRangeSlider
            dateRange={scrubbedDateRange}
            min={activeDateRange[0]}
            max={activeDateRange[1]}
            onChange={(event, value) => {
              if ((event as unknown as MouseEvent).shiftKey) {
                const startDateDifference = moment(value[0]).diff(moment(scrubbedDateRange[0]), 'seconds');
                const endDateDifference = moment(value[1]).diff(moment(scrubbedDateRange[1]), 'seconds');
                setScrubbedDateRange([
                  moment(value[0]).add(endDateDifference, 'seconds').toDate(),
                  moment(value[1]).add(startDateDifference, 'seconds').toDate(),
                ]);
              } else {
                setScrubbedDateRange(value);
              }
            }}
          />
        )}
        <MapFilterPanel
          selectedCows={selectedCows || allAvailableCows}
          selectedCowGStatuses={selectedCowGStatuses || allAvailableCowGStatuses}
          allAvailableCows={allAvailableCows}
          allAvailableCowgStatuses={allAvailableCowGStatuses}
          onSelectedCowsChange={setSelectedCows}
          onSelectedCowGStatusesChange={setSelectedCowGStatuses}
        />
      </BottomMapWrapper>
    </Wrapper>
  );
};

export default MapPanel2;
