import { createSlice } from '@reduxjs/toolkit';

import { reset } from 'store/actions';
import { ITowerDTO } from '@halter-corp/infrastructure-service-client';
import infrastructureEffects from 'store/effects/infrastructure.effects';

type InfrastructureState = {
  latLngTowers: ITowerDTO[];
};

const initialState: InfrastructureState = {
  latLngTowers: [],
};

const infrastructureSlice = createSlice({
  initialState,
  name: 'infrastructure',
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(reset, () => initialState)
      .addCase(infrastructureEffects.fetchTowersByLatLngAndRadius.fulfilled, (state, action) => {
        state.latLngTowers = action.payload;
      }),
});

export default infrastructureSlice;
