import {
  IBulkMarkAsMatedRequestDTO,
  ICalvingDTO,
  ICowHeatEventDTO,
  ICowSeasonDTO,
  ICreateCalvingDTO,
  ICreateManualCowHeatEventDTO,
  ICreateSeasonDTO,
  IUpdateSeasonDTO,
} from '@halter-corp/cattle-breeding-service-client';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';
import { useSelector } from 'react-redux';
import store from 'store';
import behaviourEffects from 'store/effects/behaviour.effects';

import {
  isHeatsLoading,
  selectCowHeats,
  selectFarmHeats,
  selectTaggedCowsInSeason,
  selectTaggedCowsInSeasonMetrics,
  selectSeasonMetricsSummary,
  selectMindaIntegrationStatus,
  selectCalvingInfo,
  isCalvingInfoLoading,
  selectCalvingSummary,
  selectInterventions,
  isInterventionsLoading,
  selectFarmCurrentHealthCases,
  selectFarmHistoricalHealthCases,
  selectCowHealthCases,
} from 'store/selectors/behaviour.selectors';
import HttpApiService from './http-api.service';

const BehaviourService = {
  useSelectFarmHeats: () => useSelector(selectFarmHeats),
  useSelectCowHeats: () => useSelector(selectCowHeats),
  useSelectSeasonMetrics: () => useSelector(selectTaggedCowsInSeasonMetrics),
  useSelectSeasonMetricsSummary: () => useSelector(selectSeasonMetricsSummary),
  useSelectSeason: () => useSelector(selectTaggedCowsInSeason),
  useSelectFarmCurrentHealthCases: () => useSelector(selectFarmCurrentHealthCases),
  useSelectFarmHistoricalHealthCases: () => useSelector(selectFarmHistoricalHealthCases),
  useSelectCowHealthCases: () => useSelector(selectCowHealthCases),
  useIsHeatsLoading: () => useSelector(isHeatsLoading),
  useMindaIntegrationStatus: () => useSelector(selectMindaIntegrationStatus),
  useSelectCalvingInfo: () => useSelector(selectCalvingInfo),
  useIsCalvingInfoLoading: () => useSelector(isCalvingInfoLoading),
  useSelectCalvingSummary: () => useSelector(selectCalvingSummary),
  useSelectInterventions: () => useSelector(selectInterventions),
  useIsInterventionsLoading: () => useSelector(isInterventionsLoading),

  updateSeason: async (seasonId: string, farmId: string, iUpdateSeasonDTO: IUpdateSeasonDTO) => {
    const seasonApi = await HttpApiService.getSeasonApi(farmId);
    await seasonApi.updateSeason(seasonId, iUpdateSeasonDTO);
    unwrapResult(await store.dispatch(behaviourEffects.fetchSeason({ farmId })));
    unwrapResult(await store.dispatch(behaviourEffects.fetchMetricsSummary({ farmId })));
    unwrapResult(await store.dispatch(behaviourEffects.fetchMetricData({ farmId })));
  },

  createSeason: async (farmId: string, iCreateSeasonDTO: ICreateSeasonDTO) => {
    const seasonApi = await HttpApiService.getSeasonApi(farmId);
    await seasonApi.createSeason(iCreateSeasonDTO);
    unwrapResult(await store.dispatch(behaviourEffects.fetchSeason({ farmId })));
    unwrapResult(await store.dispatch(behaviourEffects.fetchMetricsSummary({ farmId })));
    unwrapResult(await store.dispatch(behaviourEffects.fetchMetricData({ farmId })));
  },

  fetchMatingInsights: async (props: { farmId: string; cattleId: string }): Promise<ICowSeasonDTO> => {
    const cowSeasonApi = await HttpApiService.getSeasonApi(props.farmId);
    const { data: matingInsights } = await cowSeasonApi.getCowForSeason('current', props.cattleId);
    return matingInsights;
  },

  createHeatEvent: async (
    seasonId: string,
    farmId: string,
    iCreateManualCowHeatEventDTO: ICreateManualCowHeatEventDTO
  ): Promise<ICowHeatEventDTO> => {
    const seasonApi = await HttpApiService.getSeasonApi(farmId);
    const { data: heatEventCreated } = await seasonApi.createManualHeatEvent(
      seasonId,
      iCreateManualCowHeatEventDTO
    );
    return heatEventCreated;
  },

  bulkMarkAsMated: async (
    seasonId: string,
    farmId: string,
    iBulkMarkAsMatedRequestDTO: IBulkMarkAsMatedRequestDTO
  ) => {
    const seasonApi = await HttpApiService.getSeasonApi(farmId);
    await seasonApi.bulkMarkAsMated(seasonId, iBulkMarkAsMatedRequestDTO);
    unwrapResult(await store.dispatch(behaviourEffects.fetchHeats({ farmId })));
  },

  deleteManualHeatEvent: async (seasonId: string, farmId: string, heatEventId: string) => {
    const seasonApi = await HttpApiService.getSeasonApi(farmId);
    await seasonApi.deleteHeatEvent(seasonId, heatEventId);
    unwrapResult(await store.dispatch(behaviourEffects.fetchHeats({ farmId })));
  },

  deleteCalving: async (calvingId: string, farmId: string) => {
    const calvingApi = await HttpApiService.getCalvingApi(farmId);
    await calvingApi.deleteCalving(calvingId);
    unwrapResult(await store.dispatch(behaviourEffects.fetchCalvingInfo({ farmId })));
    unwrapResult(await store.dispatch(behaviourEffects.fetchCalvingSummary({ farmId })));
  },

  getHeatEventForMatingDate: async (
    seasonId: string,
    farmId: string,
    matingDate: Date,
    cattleId: string
  ): Promise<ICowHeatEventDTO | undefined> => {
    const seasonApi = await HttpApiService.getSeasonApi(farmId);
    const { data: heatEvents } = await seasonApi.findHeatEvents(seasonId, 100, cattleId);
    return heatEvents.find((heatEvent) =>
      moment(matingDate).isBetween(
        moment(heatEvent.firstDetectedAt).subtract(36, 'hours'),
        heatEvent.expiresAt
      )
    );
  },

  triggerIngestMindaData: async (farmId: string): Promise<string> => {
    const mindaAdapterApi = await HttpApiService.getMindaAdapterApi(farmId);
    try {
      await mindaAdapterApi.ingestMindaData(farmId);
      return 'Success';
    } catch (err) {
      const error = err as any;
      return `Fail: ${error.response.data.message}`;
    }
  },

  uploadCalvingDates: async (createCalvingDTO: ICreateCalvingDTO, farmId: string): Promise<ICalvingDTO> => {
    const calvingApi = await HttpApiService.getCalvingApi(farmId);
    const { data } = await calvingApi.createCalving(createCalvingDTO);
    return data;
  },

  deleteIntervention: async (interventionId: string, farmId: string, seasonId: string) => {
    const interventionApi = await HttpApiService.getInterventionApi(farmId);
    await interventionApi.deleteIntervention(interventionId);
    unwrapResult(await store.dispatch(behaviourEffects.fetchInterventions({ seasonId, farmId })));
  },
};

export default BehaviourService;
