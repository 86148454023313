import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../store';

export const selectInfrastructure = createSelector(
  (state: AppState) => state.infrastructure,
  (infrastructure) => infrastructure
);

export const selectLatLngTowers = createSelector(
  selectInfrastructure,
  (infrastructure) => infrastructure.latLngTowers
);
