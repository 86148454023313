import { ICowSeasonDTO } from '@halter-corp/cattle-breeding-service-client';
import { Box, IconButton, Popover, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { dateToText } from 'application/modules/fleet-watch/utils';
import React, { FunctionComponent, useState } from 'react';
import BehaviourService from 'services/behaviour.service';

type MatingInsightsPopupProps = {
  cattleId: string;
  farmId: string;
};

const MatingInsightsPopup: FunctionComponent<MatingInsightsPopupProps> = ({ cattleId, farmId }) => {
  const [insights, setInsights] = useState<ICowSeasonDTO | undefined>(undefined);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(Boolean(anchorEl));

  const fetchMatingInsights = async () => {
    const cowSeasonDTO = await BehaviourService.fetchMatingInsights({ farmId, cattleId });
    setInsights(cowSeasonDTO);
  };

  const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    fetchMatingInsights();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <Info style={{ color: 'black' }} />
      </IconButton>
      {insights && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Box p={1}>
            <Typography>{`Calving Date: ${dateToText(insights.lastCalvedDate)}`}</Typography>
            <Typography>{`Heat Events this season: ${insights.heatEvents.length}`}</Typography>
            {insights.heatEvents.length > 0 &&
              insights.heatEvents.map((event) => (
                <Typography key={event.id}>{dateToText(event.firstDetectedAt)}</Typography>
              ))}
          </Box>
        </Popover>
      )}
    </>
  );
};

export default MatingInsightsPopup;
